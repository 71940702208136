import revive_payload_client_MRRStk4O5X from "D:/Project/vue/frontend_new/node_modules/.pnpm/nuxt@3.8.2_@parcel+watcher@2.4.1_@types+node@18.19.23_encoding@0.1.13_eslint@8.57.0_idb-keyva_hodxbhe4krkirbev4s6yultzwa/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_u7SKu5TBWG from "D:/Project/vue/frontend_new/node_modules/.pnpm/nuxt@3.8.2_@parcel+watcher@2.4.1_@types+node@18.19.23_encoding@0.1.13_eslint@8.57.0_idb-keyva_hodxbhe4krkirbev4s6yultzwa/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_DpcHgxIh6O from "D:/Project/vue/frontend_new/node_modules/.pnpm/nuxt@3.8.2_@parcel+watcher@2.4.1_@types+node@18.19.23_encoding@0.1.13_eslint@8.57.0_idb-keyva_hodxbhe4krkirbev4s6yultzwa/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import components_plugin_KR1HBZs4kY from "D:/Project/vue/frontend_new/.nuxt/components.plugin.mjs";
import prefetch_client_v609zZxUZv from "D:/Project/vue/frontend_new/node_modules/.pnpm/nuxt@3.8.2_@parcel+watcher@2.4.1_@types+node@18.19.23_encoding@0.1.13_eslint@8.57.0_idb-keyva_hodxbhe4krkirbev4s6yultzwa/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import unocss_MzCDxu9LMj from "D:/Project/vue/frontend_new/.nuxt/unocss.mjs";
import composition_a2v4JR8Owe from "D:/Project/vue/frontend_new/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.11_rollup@4.12.1_vue-router@4.3.0_vue@3.4.21_typescript@5.4.5___vue@3.4.21_typescript@5.4.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_8AgazNJwvm from "D:/Project/vue/frontend_new/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.11_rollup@4.12.1_vue-router@4.3.0_vue@3.4.21_typescript@5.4.5___vue@3.4.21_typescript@5.4.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_LrdiMjsjDs from "D:/Project/vue/frontend_new/node_modules/.pnpm/nuxt@3.8.2_@parcel+watcher@2.4.1_@types+node@18.19.23_encoding@0.1.13_eslint@8.57.0_idb-keyva_hodxbhe4krkirbev4s6yultzwa/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_6XJfV3KVf7 from "D:/Project/vue/frontend_new/node_modules/.pnpm/nuxt@3.8.2_@parcel+watcher@2.4.1_@types+node@18.19.23_encoding@0.1.13_eslint@8.57.0_idb-keyva_hodxbhe4krkirbev4s6yultzwa/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import query_client_dJ5u6mIYKB from "D:/Project/vue/frontend_new/src/plugins/query-client.ts";
import sentry_client_KAXFuL2wum from "D:/Project/vue/frontend_new/src/plugins/sentry.client.ts";
import theme_jPQ6APFowV from "D:/Project/vue/frontend_new/src/plugins/theme.ts";
export default [
  revive_payload_client_MRRStk4O5X,
  unhead_u7SKu5TBWG,
  router_DpcHgxIh6O,
  components_plugin_KR1HBZs4kY,
  prefetch_client_v609zZxUZv,
  unocss_MzCDxu9LMj,
  composition_a2v4JR8Owe,
  i18n_8AgazNJwvm,
  chunk_reload_client_LrdiMjsjDs,
  check_outdated_build_client_6XJfV3KVf7,
  query_client_dJ5u6mIYKB,
  sentry_client_KAXFuL2wum,
  theme_jPQ6APFowV
]