import { reactiveComputed } from '@vueuse/core/index'
import BigNumber, { default as bignumber } from 'bignumber.js'

interface ResourceParams {
  resourceType: number
  rentTimeUnit: 'h' | 'd' | 'm'
  rentDuration: number
}

interface DiscountParams {
  rentTimeUnit: 'h' | 'd' | 'm'
  rentDuration: number
  resourceValue: number | null
  resourceType: number
  priceInSun: number

}

/**
 * 资源价格区间，用于输入框限制及输入判断
 * @param resourceType TronRes
 */
export function resourcePriceRange(resourceType: Ref<number>) {
  const { config } = useAppState()
  return reactiveComputed(() => {
    const range = {
      c2cMinValue: 0,
      c2cMaxValue: 0,
    }
    if (!config.value?.order)
      return range
    const order = config.value?.order
    range.c2cMinValue = unref(resourceType)
      ? order.energy_c2c_min_value
      : order.bandwidth_c2c_min_value
    range.c2cMaxValue = unref(resourceType)
      ? order.energy_c2c_max_value
      : order.bandwidth_c2c_max_value

    return range
  })
}

/**
 * 单价区间
 * @param params
 */
export function resourceUnitPrice<T extends ResourceParams>(params: T) {
  const { config } = useAppState()
  const resourceType = computed(() => params.resourceType ? 'energy' : 'bandwidth')
  return reactiveComputed(() => {
    const type = unref(resourceType)

    const range = {
      c2cMinSun: config.value?.price_c2c[type]!.sun ?? 90, // 最小值
      c2cMaxSun: 0,
    }

    const source = config.value?.price_c2c[type]

    if (!source)
      return range
    if (params.rentTimeUnit === 'm')
      range.c2cMinSun = source.sun_m

    if (params.rentTimeUnit === 'd') {
      if (params.rentDuration === 1)
        range.c2cMinSun = source.sun_1d || 0
      if (params.rentDuration === 2)
        range.c2cMinSun = source.sun_2d || 0
    }
    else if (params.rentTimeUnit === 'h') {
      if (params.rentDuration === 1)
        range.c2cMinSun = source.sun_1h || 0
      else if (params.rentDuration === 6)
        range.c2cMinSun = source.sun_6h || 0
      else if (params.rentDuration === 3)
        range.c2cMinSun = source.sun_3h || 0
    }

    range.c2cMaxSun = source?.max_sun

    return range
  })
}

/**
 * 默认单价
 * @param params
 */
export function resourceDefaultPrice<T extends ResourceParams>(params: T) {
  const { config } = useAppState()
  const resourceType = computed(() => params.resourceType ? 'energy' : 'bandwidth')
  return computed(() => {
    const type = resourceType.value.toLowerCase()
    // @ts-expect-error
    const source = config.value?.price_c2c[type]
    if (!source)
      return 0
    let defaultPriceInSun = source.default_sun

    if (params.rentTimeUnit === 'm')
      defaultPriceInSun = source.default_sun_m

    if (params.rentTimeUnit === 'd') {
      if (params.rentDuration === 1)
        defaultPriceInSun = source.default_sun_1d
      if (params.rentDuration === 2)
        defaultPriceInSun = source.default_sun_2d
    }
    else if (params.rentTimeUnit === 'h') {
      if (params.rentDuration === 1)
        defaultPriceInSun = source.default_sun_1h
      else if (params.rentDuration === 6)
        defaultPriceInSun = source.default_sun_6h
      else if (params.rentDuration === 3)
        defaultPriceInSun = source.default_sun_3h
    }
    return defaultPriceInSun
  })
}

/**
 * 计算折扣
 * @param params
 * todo 折扣类型返回有问题
 */
export function discountResult<T extends DiscountParams>(params: T) {
  return reactiveComputed(() => {
    const { config } = useAppState()
    const info = {
      fee: bignumber(0),
      amount: bignumber(0),
      save: bignumber(0),
      discount: bignumber(0),
      needBurning: bignumber(0),
    }
    if (!params.priceInSun || !params.resourceValue)
      return info
    const day = params.rentTimeUnit === 'd' ? bignumber(params.rentDuration) : bignumber(1)

    const needBurning = params.resourceType
      ? bignumber(params.resourceValue).multipliedBy((config.value?.estimate.energy_price ?? 210)).dividedBy(1000000).multipliedBy(day)
      : bignumber(1000).dividedBy(1000000).multipliedBy(params.resourceValue).multipliedBy(day)

    info.needBurning = needBurning
    info.amount = calcAmount(params)

    // todo 此处将toFixed 改成 BigNumber.ROUND_DOWN 截断
    info.save = needBurning.minus(info.amount).isNegative()
      ? new BigNumber(0)
      : needBurning.minus(info.amount).decimalPlaces(2, BigNumber.ROUND_DOWN)

    const discountFormula = bignumber(100).multipliedBy(bignumber(1).minus(info.amount.dividedBy(needBurning)))
    info.discount = discountFormula.isNegative()
      ? bignumber(0)
      : discountFormula.decimalPlaces(0, BigNumber.ROUND_DOWN)

    return info
  })
}

function calcAmount<T extends DiscountParams>(params: T) {
  let payAmount = bignumber(0)
  switch (params.rentTimeUnit) {
    case 'd':
      if (params.rentDuration < 1 || params.rentDuration > 30)
        break

      payAmount = bignumber((params.resourceValue ?? 0))
        .multipliedBy(params.priceInSun)
        .dividedBy(1000000)
        .multipliedBy(params.rentDuration)
        .decimalPlaces(6, BigNumber.ROUND_DOWN)
      break
    //   默认：小时、分钟
    default:
      if (params.rentDuration < 1 || params.rentDuration > 23)
        break

      payAmount = bignumber((params.resourceValue ?? 0))
        .multipliedBy(params.priceInSun)
        .dividedBy(1000000)
        .multipliedBy(1)
        .decimalPlaces(6, BigNumber.ROUND_DOWN)
      break
  }
  return payAmount
}
