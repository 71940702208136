import { createInjectionState, reactiveComputed, useEventBus } from '@vueuse/core'
import { TronRes, tron } from '~/utils/tron-v2'
import { EPaymentMethod } from '~/components/pay-type/src/PayType.vue'
import { imtoken } from '~/utils/imtoken'
import { useRentalTime } from '~/composables/useRentalTime'
import { discountResult, resourceDefaultPrice, resourcePriceRange, resourceUnitPrice } from '~/composables/app/getC2cAmountCalculator'
import { useUserInfo } from '~/composables/app'

interface IFormData {
  resourceType: number
  receiveAddress: string
  resourceValue: number | null
  priceInSun: number
  rentTimeSecond: number
  rentTimeUnit: 'h' | 'd' | 'm'
  rentDuration: number
  times: number
  waitSecond: number
}
export const [useBuyProvider, useBuy] = createInjectionState(() => {
  const { config } = useAppState()
  const { shortcutTime } = useRentalTime('c2c')
  const { account, isConnected } = tronComp.useAccountCurrent()
  const formData = reactive<IFormData>({
    resourceType: TronRes.ENERGY,
    receiveAddress: '',
    resourceValue: null,
    priceInSun: 0,
    rentTimeSecond: 259200,
    rentTimeUnit: 'd',
    rentDuration: 3,
    times: 1, // 笔数
    waitSecond: 1800, // 笔数
  })

  watch(config, (val) => {
    console.log('val', val)
    if (val?.order.time_range_c2c[0] === 600) {
      formData.rentTimeSecond = 600
      formData.rentTimeUnit = 'm'
      formData.rentDuration = 10
    }
  }, { immediate: true, deep: true })

  const fromDataRefs = toRefs(formData)

  const unitPriceRange = resourcePriceRange(fromDataRefs.resourceType)
  const priceRange = resourceUnitPrice(formData)
  const defaultPriceInSun = resourceDefaultPrice(formData)
  const discount = discountResult(formData)

  const buyForm = useAntFormValidator({
    resourceType: {
      value: fromDataRefs.resourceType,
    },
    receiveAddress: {
      value: fromDataRefs.receiveAddress,
      validator(rule, value) {
        if (!value)
          return Promise.reject($t('koczkm929Fmicnja2sYs4'))

        if (isUnaddressDom.value) {
          if (!imtoken.isImToken() && !tron.isAddress(value))
            return Promise.reject($t('mbKTfi73xuZvYVdZnac7'))
        }
        return Promise.resolve()
      },
    },
    resourceValue: {
      value: fromDataRefs.resourceValue,
      validator(rule, value) {
        const type = resourceType.value === 'ENERGY' ? $t('Mqe_hlD_yGPsAMB9oaO1D') : $t('xo7kGD9R2fV50wOIihlhL')

        if (typeof value !== 'number')
          return Promise.reject($t('cO4MIzIlIHzj5eWyvgb16', { type }))

        if (!value || value <= 0)
          return Promise.reject($t('Z3dE8F8H0GPEYq_juWdf5'))

        if (value < unitPriceRange.c2cMinValue)
          return Promise.reject(`${$t('9Air0S8Iviz5hpGdRb9Za')} : ${unitPriceRange.c2cMinValue}`)

        if (value > unitPriceRange.c2cMaxValue)
          return Promise.reject(`${$t('onYvOfwpMf8GcNrAc5r4H')} ${unitPriceRange.c2cMaxValue}`)

        return Promise.resolve()
      },
    },
    priceInSun: {
      value: fromDataRefs.priceInSun,
      validator(rule, value) {
        if (typeof value !== 'number')
          return Promise.reject($t('cw6ImkkTup21CecCdWac'))

        return Promise.resolve()
      },
    },
    rentTimeSecond: {
      value: fromDataRefs.rentTimeSecond,
      validator(rule, value) {
        if (!value)
          return Promise.reject($t('dHgidzfd5qiruU6fTpsQ'))

        return Promise.resolve()
      },
    },
    waitSecond: {
      value: fromDataRefs.waitSecond,
    },
  })

  const isPenNumber = ref(false)

  const otherMode = computed(() => !!tron.tronWeb && isConnected.value)

  const changeEnergyUnit = (e: boolean) => {
    isPenNumber.value = e
  }

  const resourceType = computed(() =>
    formData.resourceType ? 'ENERGY' : 'BANDWIDTH',
  )
  const shortcut = computed(() => resourceType.value === 'ENERGY' ? config.value?.order.energy_c2c_quick_options : config.value?.order.bandwidth_quick_options)

  const chooseRentDuration = (item?: number) => {
    item = item ?? formData.rentTimeSecond
    const data = shortcutTime?.find((i: any) => i.value === item)
    if (!data)
      return
    formData.rentTimeSecond = data.value
    formData.rentDuration = data.dur
    formData.rentTimeUnit = data.unit
    formData.priceInSun = defaultPriceInSun.value
    buyForm.$form.value?.clearValidate()
  }

  const isOpenTTPay = computed(() => config.value?.base.enable_ttpay)

  /** 是否下载有tron钱包插件 */
  const isUnaddressDom = computed(() => typeof window.tronWeb === 'object' || imtoken.isImToken())

  const onSubmit = async () => {}

  const onValidate = async () => {}

  const payType = ref(EPaymentMethod.DAPP)

  const paymentMethod = computed(() => {
    switch (payType.value) {
      case EPaymentMethod.DAPP:
        return 'dapp'
      case EPaymentMethod.TTPAY:
        return 'ttpay'
      case EPaymentMethod.BALANCE:
        return 'balance'
      default:
        return 'dapp'
    }
  })

  const togglePayType = (code: number) => {
    payType.value = code
  }
  const { trxMoney } = useUserInfo()
  /** 按钮组显示条件 */
  const buttonGroup = reactiveComputed(() => {
    return {
      walletConnection:
        !toValue(isOpenTTPay)
        && toValue(isUnaddressDom)
        && !toValue(isConnected),
      walletConnectionToPay:
        toValue(payType) === EPaymentMethod.DAPP
        && toValue(isUnaddressDom)
        && !toValue(isConnected),
      download:
        toValue(payType) === EPaymentMethod.DAPP && !toValue(isUnaddressDom),
      pay:
        toValue(payType) === EPaymentMethod.TTPAY
        // eslint-disable-next-line style/no-mixed-operators
        || toValue(payType) === EPaymentMethod.BALANCE && (unref(trxMoney) ?? 0) >= toDecimalPlaces(discount.amount, 6)
        || (toValue(payType) === EPaymentMethod.DAPP && toValue(isConnected)),
      balanceTip: toValue(payType) === EPaymentMethod.BALANCE && (unref(trxMoney) ?? 0) < toDecimalPlaces(discount.amount, 6),
    }
  })

  const isRentDays = computed(() => formData.rentTimeUnit === 'd' && formData?.rentDuration > 1)

  const paymentAddress = computed(
    () => config.value?.wallet?.payment_c2c_address,
  )

  const bus = useEventBus<string>('SWITCH-WALLET')

  // 监听钱包切换
  bus.on(() => {
    formData.receiveAddress = account.address
  })

  watchEffect(() => {
    formData.priceInSun = defaultPriceInSun.value
  })

  watch(isConnected, () => {
    formData.receiveAddress = isConnected.value ? account.address : formData.receiveAddress
  }, { immediate: true })

  watch(
    () => formData.resourceType,
    () => {
      buyForm.$form.value?.clearValidate()
      formData.resourceValue = null
    },
  )

  return {
    shortcut,
    changeEnergyUnit,
    unitPriceRange,
    priceRange,
    shortcutTime,
    defaultPriceInSun,
    formData,
    buyForm,
    chooseRentDuration,
    discount,
    onSubmit,
    isOpenTTPay,
    isUnaddressDom,
    onValidate,
    togglePayType,
    payType,
    buttonGroup,
    paymentAddress,
    paymentMethod,
    isRentDays,
    isPenNumber,
    otherMode,
  }
})

interface TDiscount {
  fee: number
  amount: number
  save: number
  discount: number
}
export type { TDiscount }
