<script lang="ts" setup>
import { useSwichLocale } from '~/locales'
import { useUserInfo } from '~/composables/app'
import agent1en from '@/assets/imgs/agent1-en.png'
import agent2en from '@/assets/imgs/agent2-en.png'
import agent1zh from '@/assets/imgs/agent1-zh.png'
import agent2zh from '@/assets/imgs/agent2-zh.png'

const { userInfo } = useUserInfo()
const { locale } = useSwichLocale()
const { isAgent, uniqueId } = useUserInfo()

const iframeText1 = computed(() => {
  return `<iframe style="min-height: 100%; width: 100%; overflow: hidden;" frameborder="0" id="feee_c2c_widget" src="http://${!needApply.value ? `{${$t('1KAaEUXJQTXCLlvSHC8ZG')}}` : uniqueId.value}.feee.vip?theme=dark"></iframe>
`
})
const iframeText2 = computed(() => {
  return ` <iframe style="min-height: 100%; width: 100%; overflow: hidden;" frameborder="0" id="feee_c2c_widget" src="http://${!needApply.value ? `{${$t('1KAaEUXJQTXCLlvSHC8ZG')}}` : uniqueId.value}.feee.vip?theme=light"></iframe>
`
})

const sdk = computed(() => {
  return `<srcipt src="https://${!needApply.value ? `{${$t('1KAaEUXJQTXCLlvSHC8ZG')}}` : uniqueId.value}.feee.vip/agentSDK.js"></srcipt>`
})
const meta = computed(() => {
  return `<meta charset="UTF-8" name="viewport" content="width=device-width, initial-scale=1.0">`
})

const needApply = computed(() => {
  console.log('isAgent', isAgent)
  return toValue(isAgent) === 1
})

const domain = computed(() => {
  return env.prod ? 'feee.vip' : 'tron-gas.org'
})
const main = reactive([
  { icon: 'i-local:moon?mask', name: $t('z6ojVxOls6WpWAzjgSk'), img: locale.value === 'zh' ? agent1zh : agent1en, isShow: false, iframeText: iframeText1, sdk, meta },
  { icon: 'i-local:sun?mask', name: $t('xzOilqAv3OyXiNn0fn0Sb'), img: locale.value === 'zh' ? agent2zh : agent2en, isShow: false, iframeText: iframeText2, sdk, meta },
])
function setVisible(index: number, value: boolean) {
  main[index].isShow = value
}
</script>

<template>
  <div class="agent-wrap page-content-width">
    <h1>
      <p class="pl-20px">{{ $t("1VRKMWg39jbqkXZiqxtRT") }}</p>
      <a class="lt-tablet:hidden" :href="$t('yYPh4hekIrY9KQrxi2ti')" data-test-id="2SFx">
        <span class="btn">{{ needApply ? $t('08ialwyEFgeiF5BEr0Wsv') : $t("FeHv-H2QMmZHkm92bxLYc") }}</span>
      </a>
    </h1>
    <div class="main">
      <p class="info">
        <I18nT keypath="0Iibv5juInpkUkepHAtpm">
          <template #name>
            <a
              :href="$t('lnDxe6nPZxUxRy7Gg1S7c', { domain })"
              :target="$bp.laptop ? '_blank' : '_self'"
              class="text-[--highlight]"
              data-test-id="K1iq"
            >{{ $t("KR917G3vytfxjJAlakuAg") }}</a>
          </template>
        </I18nT>
      </p>
      <a :href="$t('yYPh4hekIrY9KQrxi2ti')" data-test-id="9G46">
        <AButton
          class="comfirm-btn mt-16px w-100% hidden! lt-tablet:block!"
        >
          {{ needApply ? $t('08ialwyEFgeiF5BEr0Wsv') : $t("FeHv-H2QMmZHkm92bxLYc") }}
        </AButton>
      </a>
      <p class="info my-20px">{{ $t('d5KEjM3HmCydl8S536pb') }}:</p>
      <div class="flex gap-20px lt-tablet:flex-wrap">
        <div v-for="(item, index) in main" :key="index" class="code">
          <div class="tips">
            <p class="text-16px">
              <i :class="`${item.icon}` " class="text-18px mr-6px mb-3px lt-tablet:text-#266ef1" />{{ item.name }}
            </p>
            <p class="text-14px text-#266ef1 hidden lt-tablet:block" data-test-id="qma3" @click="setVisible(index, true)">
              {{ $t('dMq8fZsuoPiuWnWspt5q') }}
              <AImage
                :style="{ display: 'none' }"
                :preview="{
                  visible: item.isShow,
                  onVisibleChange: (visible) => setVisible(index, visible),
                }"
                :src="item.img"
              />
            </p>
          </div>
          <div class="bg-#13192e text-center border-rd-[10px_10px_0_0] lt-tablet:hidden">
            <AImage
              style="width: 72%"
              :src="item.img"
            />
          </div>
          <div class="relative py-20px px-30px lt-tablet:py-10px lt-tablet:pl-15px lt-tablet:pr-40px">
            {{ item.meta }}
            <br>
            {{ item.sdk }}
            <br>
            {{ item.iframeText }}
            <Copy class="copy" :content="item.sdk + item.iframeText" />
            <!-- 遮罩层 -->
            <a v-if="userInfo?.is_agent !== 1" class="mask h-100% w-100%" :href="$t('yYPh4hekIrY9KQrxi2ti')" data-test-id="FkRz">
              <p class="relative top-45% text-center lt-tablet:w-72% lt-tablet:m-auto ">{{ $t('iAgW99c02UqiReLuhtrd0') }}</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.ant-image .ant-image-mask){
  border-radius: 10px 10px 0 0;
}

.agent-wrap {
  --uno: 'page-max-width mx-auto mt-40px mb-45px lt-tablet:mb-16px';

  h1 {
    --uno: 'flex items-start justify-between px-40px py-30px text-30px font-bold';

    color: var(--first-text);
    background: linear-gradient(180deg, #24305A, #192242);
    border-radius: 30px 30px 0 0;

    p {
      position: relative;

      &::before {
        position: absolute;
        top: 2px;
        left: 0;
        width: 4px;
        height: 26px;
        content: '';
        background: var(--highlight);
      }
    }
  }

  .main {
    --uno: 'min-h-370px bg-#1a2342 px-40px py-0 text-18px';

    border-radius: 0 0 30px 30px;

    .info{
      --uno: 'text-[--first-text] lh-1.8em text-18px';
    }
  }

  .code {
    --uno: 'relative text-18px mx-0 mb-114px border-rd-10px bg-#202c52 min-h-500px lh-30px w-100%';

    color: var(--secondary-text);
    word-break: break-word;

    .tips{
      --uno: 'absolute inline-block px-20px py-10px border-rd-[10px_0] bg-[#267eeb] text-[#e2e8f2]';

      z-index: 1000;
    }

    .copy {
      --uno: 'absolute top-25px right-15px';
    }
  }

  .mask{
    position: absolute;
    top: 0;
    left: 0;
    color: #e2e8f2;
    background: rgb(0 0 0 / 50%);
    backdrop-filter: blur(2px);
    border-radius: 0 0 10px 10px;

    &:hover{
      color: var(--highlight);
    }
  }

  .btn {
    --uno: 'w-200px h-50px flex-center text-[#fff] border-rd-10px text-20px cursor-pointer';

    background: var(--highlight);

    &:hover{
      background: linear-gradient(90deg, #2680EB, #00C1A5) no-repeat;
    }
  }
}

@media bp-lt-tabletl {
  .agent-wrap {
    --uno: 'mt-16px';

    h1 {
      --uno: 'py-14px px-16px text-26px';

      border-radius: 16px 16px 0 0;
    }

    .main {
      --uno: 'px-16px pb-20px text-16px';

      border-radius: 0 0 16px 16px;

      .info{
        --uno: 'text-16px';
      }
    }

    .code {
      --uno: 'text-16px my-0 min-h-auto';

      .tips{
        --uno: 'relative flex items-center justify-between px-10px py-5px border-rd-[10px_0] bg-[transparent]';
      }
    }

    .btn {
      --uno: 'w-120px h-40px border-rd-8px text-14px';
    }
  }
}
</style>
